import React, { useState } from "react";
import CustomerModal from "./CustomerModal";
import customers from "../data/customers";
import "../styles/effects.css";

const AdventCalendar = () => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [effectActive, setEffectActive] = useState(false);
  const [effectType, setEffectType] = useState(null);

  const doorMapping = {
    0: 16,
    1: 9,
    2: 20,
    3: 1,
    4: 15,
    5: 22,
    6: 17,
    7: 12,
    8: 19,
    9: 6,
    10: 8,
    11: 13,
    12: 18,
    13: 4,
    14: 21,
    15: 11,
    16: 2,
    17: 3,
    18: 23,
    19: 5,
    20: 7,
    21: 10,
    22: 14,
    23: 24,
  };

  const playEffect = async (index) => {
    const effect = Math.random() > 0.5 ? "wind" : "lightning";
    setEffectType(effect);
    setEffectActive(true);

    await new Promise((resolve) => setTimeout(resolve, 500));
    setEffectActive(false);

    const doorNumber = doorMapping[index];
    const customerData = customers[doorNumber];
    if (customerData) {
      setSelectedCustomer(customerData);
      setIsModalOpen(true);
    }
  };

  const handleDoorClick = (index) => {
    const doorNumber = doorMapping[index];
    const currentDate = new Date();
    const isDecember = currentDate.getMonth() === 11;
    const currentDay = currentDate.getDate();

    if (!isDecember || doorNumber > currentDay) {
      //alert("Dieses Türchen kann noch nicht geöffnet werden!");
      //return;
    }

    const currentCustomerData = customers[doorNumber];
    if (!currentCustomerData) {
      console.warn(`Keine Daten für Türchen ${doorNumber} gefunden`);
      return;
    }

    playEffect(index);
  };

  return (
    <div className="container mx-auto px-4">
      {/* Desktop Version */}
      <div
        className={`hidden md:block ${
          effectActive ? effectType + "-effect" : ""
        }`}
      >
        <div className="relative">
          <img
            src="/calendar-background.svg"
            alt="Adventskalender Desktop"
            className="w-full"
          />
          <div className="absolute top-0 left-0 w-full h-full grid grid-cols-6 grid-rows-4">
            {Array.from({ length: 24 }).map((_, index) => (
              <button
                key={`desktop-${index}`}
                onClick={() => handleDoorClick(index)}
                className="transition-all duration-300 hover:bg-white/10"
              />
            ))}
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div
        className={`md:hidden ${effectActive ? effectType + "-effect" : ""}`}
      >
        <div className="relative">
          <img
            src="/calendar-background-mobile.svg"
            alt="Adventskalender Mobile"
            className="w-full"
          />
          <div className="absolute top-0 left-0 w-full h-full grid grid-cols-2 grid-rows-12">
            {Array.from({ length: 24 }).map((_, index) => (
              <button
                key={`mobile-${index}`}
                onClick={() => handleDoorClick(index)}
                className="transition-all duration-300 hover:bg-white/10"
              />
            ))}
          </div>
        </div>
      </div>

      <CustomerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        customerData={selectedCustomer}
      />
    </div>
  );
};

export default AdventCalendar;
