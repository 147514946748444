// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/calendar.css */
.calendar-container {
  aspect-ratio: 1020/680;
}

.calendar-container-mobile {
  aspect-ratio: 510/1360; /* Passe dies an das Seitenverhältnis deiner mobilen SVG an */
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/styles/calendar.css"],"names":[],"mappings":"AAAA,4BAA4B;AAC5B;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB,EAAE,6DAA6D;AACvF;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":["/* src/styles/calendar.css */\n.calendar-container {\n  aspect-ratio: 1020/680;\n}\n\n.calendar-container-mobile {\n  aspect-ratio: 510/1360; /* Passe dies an das Seitenverhältnis deiner mobilen SVG an */\n}\n\n.text-shadow {\n  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
