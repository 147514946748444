// src/components/CustomerModal.jsx
import React from "react";
import { X } from "lucide-react";

const CustomerModal = ({ isOpen, onClose, customerData }) => {
  if (!isOpen || !customerData) return null;

  return (
    <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full m-4 relative animate-fadeIn">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        {/* Customer Logo & Name Header */}
        <div className="mb-8 text-center">
          <div className="w-48 h-24 mx-auto flex items-center justify-center">
            <img
              src={customerData.customerLogo}
              alt={`${customerData.customerName} Logo`}
              className="max-w-full max-h-full object-contain"
            />
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mt-4">
            {customerData.customerName}
          </h2>
        </div>

        {/* Two Column Layout */}
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left Column - Team Member */}
          <div className="md:w-1/3 flex flex-col items-center">
            <div className="w-40 h-40 rounded-full overflow-hidden">
              <img
                src={customerData.teamMember.image}
                alt={customerData.teamMember.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="text-center mt-4">
              <p className="font-semibold text-lg">
                {customerData.teamMember.name}
              </p>
              <p className="text-gray-600">
                {customerData.teamMember.position}
              </p>
            </div>
          </div>

          {/* Right Column - Testimonial */}
          <div className="md:w-2/3 flex items-center">
            <blockquote className="text-gray-600 italic border-l-4 border-blue-500 pl-6">
              "{customerData.testimonial}"
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
