// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/effects.css */
@keyframes windEffect {
  0% {
    transform: rotate(0deg) scale(1);
    filter: blur(0);
  }
  25% {
    transform: rotate(-2deg) scale(1.02);
    filter: blur(1px);
  }
  75% {
    transform: rotate(2deg) scale(1.02);
    filter: blur(2px);
  }
  100% {
    transform: rotate(0deg) scale(1);
    filter: blur(0);
  }
}

@keyframes lightningFlash {
  0%,
  100% {
    filter: brightness(100%);
  }
  30% {
    filter: brightness(300%);
  }
  60% {
    filter: brightness(200%);
  }
}

.wind-effect {
  animation: windEffect 0.5s ease-in-out;
}

.lightning-effect {
  position: relative;
  animation: lightningFlash 0.5s ease-out;
}

.lightning-effect::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  opacity: 0;
  animation: lightningFlash 0.5s ease-out;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/effects.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;EACE;IACE,gCAAgC;IAChC,eAAe;EACjB;EACA;IACE,oCAAoC;IACpC,iBAAiB;EACnB;EACA;IACE,mCAAmC;IACnC,iBAAiB;EACnB;EACA;IACE,gCAAgC;IAChC,eAAe;EACjB;AACF;;AAEA;EACE;;IAEE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,iBAAiB;EACjB,UAAU;EACV,uCAAuC;EACvC,oBAAoB;AACtB","sourcesContent":["/* src/styles/effects.css */\n@keyframes windEffect {\n  0% {\n    transform: rotate(0deg) scale(1);\n    filter: blur(0);\n  }\n  25% {\n    transform: rotate(-2deg) scale(1.02);\n    filter: blur(1px);\n  }\n  75% {\n    transform: rotate(2deg) scale(1.02);\n    filter: blur(2px);\n  }\n  100% {\n    transform: rotate(0deg) scale(1);\n    filter: blur(0);\n  }\n}\n\n@keyframes lightningFlash {\n  0%,\n  100% {\n    filter: brightness(100%);\n  }\n  30% {\n    filter: brightness(300%);\n  }\n  60% {\n    filter: brightness(200%);\n  }\n}\n\n.wind-effect {\n  animation: windEffect 0.5s ease-in-out;\n}\n\n.lightning-effect {\n  position: relative;\n  animation: lightningFlash 0.5s ease-out;\n}\n\n.lightning-effect::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: white;\n  opacity: 0;\n  animation: lightningFlash 0.5s ease-out;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
